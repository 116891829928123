<template>
  <div class="index">
    <!-- 背景 -->
    <div class="index_video">
      <video
        src="../../assets/video/banner_bg.5f4f350.mp4"
        autoplay
        loop
        muted
      ></video>
      <div class="index_bg">
        <div class="index_bg_top">
          <the-nav></the-nav>
        </div>
        <div class="index_bg_bottom">
          <h1>开启储能行业数智化时代</h1>
          <div class="yuyue_btn" @click="booking">预约体验</div>
        </div>
      </div>
    </div>

    <!-- 项目 -->
    <div class="index_box bg_project scroll_container">
      <div class="index_box_project w1200 sticky">
        <div class="index_box_project_left">
          <div
            class="project_item"
            v-for="(item, index) in projectList"
            :key="'project_' + index"
            :class="[currentProItem == index && 'is_active']"
            @click="currentProItem = index"
          >
            <svg class="svg1" aria-hidden="true">
              <use xlink:href="#caret-right"></use>
            </svg>
            <div class="project_item_right">
              <div class="project_item_right_header">
                {{ item.name }}
              </div>
              <div class="project_item_right_con">
                <span>{{ item.description }}</span>
                <div class="detail" @click="goPage(item.value)">
                  查看详情
                  <svg class="svg2" aria-hidden="true">
                    <use xlink:href="#arrowright"></use>
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img
          class="index_box_project_right"
          :src="projectList[currentProItem].img"
          :alt="projectList[currentProItem].name"
        />
      </div>
    </div>
    <!-- 客户 -->
    <div class="index_box bg_client">
      <div class="index_box_client w1200">
        <div class="index_box_client_title">
          <span class="cn_title">客户与合作伙伴</span>
          <span class="en_title">Customers and partners</span>
        </div>
        <div class="index_box_client_list">
          <div
            class="index_box_client_list_item"
            v-for="(item, index) in clientList"
            :key="'client' + index"
          >
            <div
              class="client_logo"
              :style="{
                'background-image': 'url(' + clientList[index].url + ')',
              }"
            ></div>
            <div class="client_name">{{ item.name }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 新闻 -->
    <div class="index_box bg_news" v-show="newsList.length">
      <div class="index_box_client w1200">
        <div class="index_box_client_title">
          <span class="cn_title">新闻动态</span>
          <span class="en_title">News</span>
        </div>
        <div class="index_box_news_list">
          <div class="more_btn" @click="$router.push({ name: 'news' })">
            更多详情
            <svg class="more" aria-hidden="true">
              <use xlink:href="#arrowright"></use>
            </svg>
          </div>
          <div
            class="index_box_news_list_item"
            v-for="(item, index) in newsList"
            :key="'news' + index"
            @click="getNewsDetail(item)"
          >
            <div class="news_title">
              <span>
                {{ item.title }}
              </span>
            </div>
            <div class="news_time">{{ item.date }}</div>
            <div class="news_hr"></div>
            <div class="news_img">
              <img :src="item.coverImg" :alt="item.title" srcset="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- footer -->
    <the-footer></the-footer>
  </div>
</template>

<script>
export default {
  name: "index",
  data() {
    return {
      // 项目
      currentProItem: 0,
      projectList: [
        {
          name: "轻EMS",
          value: "/EMS",
          description:
            "专为用户侧储能电站设计的能量管理系统，摒弃传统EMS的庞杂繁琐，打造简约稳定，高效智能的站控系统。",
          img: require("@/assets/imgs/img-product-a.png"),
        },
        {
          name: "储能管家",
          value: "/energyguard",
          description:
            "储能电站全生命周期运营维护管理平台，运行监控，故障告警，运维管理，费用核算一站式全部搞定，构建多电站统筹管理数字资产平台。 ",
          img: require("@/assets/imgs/img-product-b.png"),
        },
        {
          name: "储电医生",
          value: "/doctor",
          description:
            "智能诊断储能电池常见异常类型，提供运维指导，赋能运维人员及时保养管理电池，保障电站性能，延长电站寿命，降低失控风险。",
          img: require("@/assets/imgs/img-product-c.png"),
        },
      ],
      // 客户
      clientList: [
        {
          name: "中国铁塔",
          url: require("@/assets/imgs/img-logo-customer-a.png"),
        },
        {
          name: "EDF",
          url: require("@/assets/imgs/img-logo-customer-b.png"),
        },
        {
          name: "Brightree",
          url: require("@/assets/imgs/img-logo-customer-c.png"),
        },
        {
          name: "华东电力设计",
          url: require("@/assets/imgs/img-logo-customer-d.png"),
        },
        {
          name: "绿色国网",
          url: require("@/assets/imgs/img-logo-customer-e.png"),
        },
        {
          name: "浙江国网",
          url: require("@/assets/imgs/img-logo-customer-e.png"),
        },
        {
          name: "阿里云",
          url: require("@/assets/imgs/img-logo-customer-f.png"),
        },
        {
          name: "腾讯综能工场",
          url: require("@/assets/imgs/img-logo-customer-g.png"),
        },
      ],
      // news
      newsList: [],
    };
  },
  mounted() {
    this.scrollAnimation();
    this.getNewsList();
  },
  beforeDestroy() {
    document.removeEventListener("scroll", this.scrollAnimation);
  },
  methods: {
    booking() {
      // this.$store.commit("setState", { visible: true });
      window.open(
        `https://www.fqixin.cn/fsh5/smart-form/index.html?cardId=6570209edf8ff0034dc6d51a`,
        "_blank"
      );
    },
    scrollAnimation() {
      if (/(iPhone|iPad|iPod|iOS|Android)/i.test(navigator.userAgent)) return;
      let container = document.querySelector(".scroll_container");
      window.addEventListener("scroll", () => {
        let index =
          (document.documentElement.scrollTop - container.offsetTop) /
          (container.scrollHeight - container.offsetTop);
        let step = 1 / this.projectList.length;
        this.currentProItem =
          Math.floor(index / step) >= 2
            ? 2
            : Math.floor(index / step) <= 0
            ? 0
            : Math.floor(index / step);
      });
    },
    goPage(url) {
      this.$router.push({ path: url });
    },
    getNewsList() {
      this.$getJSON("/api/news/list", { method: "GET" }).then((res) => {
        this.newsList = res.data.slice(0, 4);
        localStorage.setItem("newsList", JSON.stringify(this.newsList));
      });
    },
    getNewsDetail(item) {
      this.$router.push({
        name: "newsDetail",
        query: { id: item.id },
      });
    },
  },
};
</script>

<style lang="scss">
@import "./index.scss";
</style>
